import React from 'react';
import PropTypes from 'prop-types';

import { Video } from 'grommet';

const LazyVideoLoader = ({
  lazySrc,
  src: { mp4, webm },
  className = '',
  ...rest
}) => (
  <Video
    autoPlay
    loop
    mute
    playsInline
    controls={false}
    data-poster={lazySrc}
    className={`lazyload fade-out ${className}`}
    {...rest}
  >
    <source src={mp4} type="video/mp4" />
    <source src={webm} type="video/webm" />
  </Video>
);

LazyVideoLoader.propTypes = {
  lazySrc: PropTypes.node.isRequired,
  src: PropTypes.shape({
    mp4: PropTypes.node.isRequired,
    webm: PropTypes.node.isRequired,
  }).isRequired,
  className: PropTypes.string,
};

export default LazyVideoLoader;
