import React from 'react';
import PropTypes from 'prop-types';

const defaultSize = {
  small: '0.75rem',
  medium: '1.5rem',
  large: '3rem',
  xlarge: '6rem',
};

const Spinning = ({
  size = 'medium', color = '#333333', ...rest
}) => (
  <svg
    version="1.1"
    viewBox="0 0 32 32"
    width={defaultSize[size]}
    height={defaultSize[size]}
    fill={color}
    {...rest}
  >
    <path
      opacity=".25"
      d="M16 0 A16 16 0 0 0 16 32 A16 16 0 0 0 16 0 M16 4 A12 12 0 0 1 16 28 A12 12 0 0 1 16 4"
    />
    <path d="M16 0 A16 16 0 0 1 32 16 L28 16 A12 12 0 0 0 16 4z">
      <animateTransform
        attributeName="transform"
        type="rotate"
        from="0 16 16"
        to="360 16 16"
        dur="0.8s"
        repeatCount="indefinite"
      />
    </path>
  </svg>
);

Spinning.propTypes = {
  color: PropTypes.string,
  size: PropTypes.oneOf(['small', 'medium', 'large', 'xlarge']),
};

export default Spinning;
