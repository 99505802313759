export const publicPaths = {
  root: '/',
  solutions: '/solutions/:uid',
  industries: '/industry/:uid',
  caseStudies: '/resources/case-studies',
  caseStudy: '/resources/case-studies/:uid',
  blog: '/resources/blog-posts',
  blogPost: '/resources/blog-posts/:uid',
  piracyReports: '/resources/piracy-reports',
  piracyReport: '/resources/piracy-reports/:uid',
  about: '/about',
  contact: '/contact',
  thankYou: '/thank-you',
};

const paths = {
  ...publicPaths,
  hashes: {
    privacy: '#privacy',
    termsofuse: '#termsofuse',
  },
};

export default paths;
