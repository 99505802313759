import React from 'react';
import PropTypes from 'prop-types';

import { Image } from 'grommet';

const LazyResponsiveImageLoader = ({
  src, className = '', ...rest
}) => (
  <picture>
    <source media="(max-width: 48em)" srcSet={src('-mobile')} />
    <source media="(min-width: 48.0625em)" srcSet={src('')} />
    <Image
      src={src('')}
      data-sizes="auto"
      alt={rest.alt ?? ''}
      width="100%"
      height="100%"
      className={`lazyload fade-out ${className}`}
      {...rest}
    />
  </picture>

);

LazyResponsiveImageLoader.propTypes = {
  src: PropTypes.func.isRequired,
  className: PropTypes.string,
};

export default LazyResponsiveImageLoader;
