const globeMap = [
  {
    name: 'Redflag AI',
    coordinate: [
      37.871666,
      -122.272781,
    ],
  },
  {
    name: 'Afghanistan',
    coordinate: [
      33.93911,
      67.709953,
    ],
  },
  {
    name: 'Algeria',
    coordinate: [
      28.033886,
      1.659626,
    ],
  },
  {
    name: 'Angola',
    coordinate: [
      -11.202692,
      17.873887,
    ],
  },
  {
    name: 'Argentina',
    coordinate: [
      -38.416097,
      -63.61667199999999,
    ],
  },
  {
    name: 'Armenia',
    coordinate: [
      40.069099,
      45.038189,
    ],
  },
  {
    name: 'Australia',
    coordinate: [
      -25.274398,
      133.775136,
    ],
  },
  {
    name: 'Austria',
    coordinate: [
      47.516231,
      14.550072,
    ],
  },
  {
    name: 'Bangladesh',
    coordinate: [
      23.684994,
      90.356331,
    ],
  },
  {
    name: 'Bolivia',
    coordinate: [
      -16.290154,
      -63.58865299999999,
    ],
  },
  {
    name: 'Brazil',
    coordinate: [
      -14.235004,
      -51.92528,
    ],
  },
  {
    name: 'Bulgaria',
    coordinate: [
      42.733883,
      25.48583,
    ],
  },
  {
    name: 'Cameroon',
    coordinate: [
      7.369721999999999,
      12.354722,
    ],
  },
  {
    name: 'Canada',
    coordinate: [
      56.130366,
      -106.346771,
    ],
  },
  {
    name: 'Chile',
    coordinate: [
      -35.675147,
      -71.542969,
    ],
  },
  {
    name: 'China',
    coordinate: [
      35.86166,
      104.195397,
    ],
  },
  {
    name: 'Colombia',
    coordinate: [
      4.570868,
      -74.297333,
    ],
  },
  {
    name: 'Congo Republic',
    coordinate: [
      -0.228021,
      15.827659,
    ],
  },
  {
    name: "Cote d'Ivoire",
    coordinate: [
      7.539988999999999,
      -5.547079999999999,
    ],
  },
  {
    name: 'Czech Republic',
    coordinate: [
      49.81749199999999,
      15.472962,
    ],
  },
  {
    name: 'Democratic Republic of the Congo',
    coordinate: [
      -4.038333,
      21.758664,
    ],
  },
  {
    name: 'Domican Republic',
    coordinate: [
      18.735693,
      -70.162651,
    ],
  },
  {
    name: 'Egypt',
    coordinate: [
      26.820553,
      30.802498,
    ],
  },
  {
    name: 'Ethiopia',
    coordinate: [
      9.145000000000001,
      40.489673,
    ],
  },
  {
    name: 'Foshan',
    coordinate: [
      23.021478,
      113.121435,
    ],
  },
  {
    name: 'France',
    coordinate: [
      46.227638,
      2.213749,
    ],
  },
  {
    name: 'Germany',
    coordinate: [
      51.165691,
      10.451526,
    ],
  },
  {
    name: 'Greece',
    coordinate: [
      39.074208,
      21.824312,
    ],
  },
  {
    name: 'Guatemala',
    coordinate: [
      15.783471,
      -90.23075899999999,
    ],
  },
  {
    name: 'Hong Kong',
    coordinate: [
      22.396428,
      114.109497,
    ],
  },
  {
    name: 'Hungary',
    coordinate: [
      47.162494,
      19.5033041,
    ],
  },
  {
    name: 'India',
    coordinate: [
      20.593684,
      78.96288,
    ],
  },
  {
    name: 'Indonesia',
    coordinate: [
      -0.789275,
      113.921327,
    ],
  },
  {
    name: 'Iran',
    coordinate: [
      32.427908,
      53.688046,
    ],
  },
  {
    name: 'Iraq',
    coordinate: [
      33.223191,
      43.679291,
    ],
  },
  {
    name: 'Israel',
    coordinate: [
      31.046051,
      34.851612,
    ],
  },
  {
    name: 'Italy',
    coordinate: [
      41.87194,
      12.56738,
    ],
  },
  {
    name: 'Japan',
    coordinate: [
      36.204824,
      138.252924,
    ],
  },
  {
    name: 'Kazakhstan',
    coordinate: [
      48.019573,
      66.923684,
    ],
  },
  {
    name: 'Kenya',
    coordinate: [
      -0.023559,
      37.906193,
    ],
  },
  {
    name: 'Libya',
    coordinate: [
      26.3351,
      17.228331,
    ],
  },
  {
    name: 'Malaysia',
    coordinate: [
      4.210484,
      101.975766,
    ],
  },
  {
    name: 'Mexico',
    coordinate: [
      23.634501,
      -102.552784,
    ],
  },
  {
    name: 'Morocco',
    coordinate: [
      31.791702,
      -7.092619999999999,
    ],
  },
  {
    name: 'Mozambique',
    coordinate: [
      -18.665695,
      35.529562,
    ],
  },
  {
    name: 'Myanmar',
    coordinate: [
      21.916221,
      95.955974,
    ],
  },
  {
    name: 'Nepal',
    coordinate: [
      28.394857,
      84.12400799999999,
    ],
  },
  {
    name: 'New Zealand',
    coordinate: [
      -40.900557,
      174.885971,
    ],
  },
  {
    name: 'Nigeria',
    coordinate: [
      9.081999,
      8.675277,
    ],
  },
  {
    name: 'Pakistan',
    coordinate: [
      30.375321,
      69.34511599999999,
    ],
  },
  {
    name: 'Peru',
    coordinate: [
      -9.189967,
      -75.015152,
    ],
  },
  {
    name: 'Philippines',
    coordinate: [
      12.879721,
      121.774017,
    ],
  },
  {
    name: 'Poland',
    coordinate: [
      51.919438,
      19.145136,
    ],
  },
  {
    name: 'Romania',
    coordinate: [
      45.943161,
      24.96676,
    ],
  },
  {
    name: 'Russia',
    coordinate: [
      61.52401,
      105.318756,
    ],
  },
  {
    name: 'Saudi Arabia',
    coordinate: [
      23.885942,
      45.079162,
    ],
  },
  {
    name: 'Senegal',
    coordinate: [
      14.497401,
      -14.452362,
    ],
  },
  {
    name: 'Serbia',
    coordinate: [
      44.016521,
      21.005859,
    ],
  },
  {
    name: 'Singapore',
    coordinate: [
      1.352083,
      103.819836,
    ],
  },
  {
    name: 'South Africa',
    coordinate: [
      -30.559482,
      22.937506,
    ],
  },
  {
    name: 'South Korea',
    coordinate: [
      35.907757,
      127.766922,
    ],
  },
  {
    name: 'Spain',
    coordinate: [
      40.46366700000001,
      -3.74922,
    ],
  },
  {
    name: 'Sudan',
    coordinate: [
      12.862807,
      30.217636,
    ],
  },
  {
    name: 'Syria',
    coordinate: [
      34.80207499999999,
      38.996815,
    ],
  },
  {
    name: 'Taiwan',
    coordinate: [
      23.69781,
      120.960515,
    ],
  },
  {
    name: 'Tanzania',
    coordinate: [
      -6.369028,
      34.888822,
    ],
  },
  {
    name: 'Thailand',
    coordinate: [
      15.870032,
      100.992541,
    ],
  },
  {
    name: 'Turkey',
    coordinate: [
      38.963745,
      35.243322,
    ],
  },
  {
    name: 'Uganda',
    coordinate: [
      1.373333,
      32.290275,
    ],
  },
  {
    name: 'Ukraine',
    coordinate: [
      48.379433,
      31.1655799,
    ],
  },
  {
    name: 'United Arab Emirates',
    coordinate: [
      23.424076,
      53.847818,
    ],
  },
  {
    name: 'United Kingdom',
    coordinate: [
      55.378051,
      -3.435973,
    ],
  },
  {
    name: 'United States',
    coordinate: [
      37.09024,
      -95.712891,
    ],
  },
  {
    name: 'Uruguay',
    coordinate: [
      -32.522779,
      -55.765835,
    ],
  },
  {
    name: 'Venezuela',
    coordinate: [
      6.42375,
      -66.58973,
    ],
  },
  {
    name: 'Vietnam',
    coordinate: [
      14.058324,
      108.277199,
    ],
  },
  {
    name: 'Yemen',
    coordinate: [
      15.552727,
      48.516388,
    ],
  },
  {
    name: 'Zambia',
    coordinate: [
      -13.133897,
      27.849332,
    ],
  },
  {
    name: 'Zimbabwe',
    coordinate: [
      -19.015438,
      29.154857,
    ],
  },
];

export default globeMap;
