import React from 'react';
import PropTypes from 'prop-types';

import { Image } from 'grommet';

const ResponsiveImage = ({ src, srcMobile, ...rest }) => (
  <picture>
    <source media="(max-width: 48em)" srcSet={srcMobile} />
    <source media="(min-width: 48.0625em)" srcSet={src} />
    <Image src={src} width="100%" height="100%" alt={rest.alt ?? ''} {...rest} />
  </picture>

);

ResponsiveImage.propTypes = {
  src: PropTypes.node.isRequired,
  srcMobile: PropTypes.node.isRequired,
};

export default ResponsiveImage;
