const sdgSolutions = {
  logo: (mobile) => `/images/sdg/SDG-logo${mobile}.png`,
  goal: [
    {
      title: 'Goal 16',
      subtitle: 'Promote Justice',
      text: `In addition to developing the core functionality that underlies Redflag’s analysis suite,
        we are committed to specific projects aimed at
        solving pressing issues of international justice that require specific focus.
        Our partnership with UC Berkeley’s Human Rights Center serves as a flagship case
        in our expansion into the realm of international criminal justice proceedings.
        Redflag’s potential as a tool for amassing evidence relating to ICC litigants is vast.
        The need for a technical bedrock is evident in such important bodies
        and we are dedicated to providing a targeted and adaptable solution.`,
      icon: (mobile) => `/images/sdg/SDG-16${mobile}.png`,
      bgColor: '#f7fcff',
    },
    {
      title: 'Goal 17',
      subtitle: 'Strengthen Means of Implementation',
      text: `Redflag offers innovative AI solutions
        that promote institutional and governmental transparency.
        We work across the social spectrum to enable clarified insight on the most pressing topics
        of the day so that the voices of those not often heard reach a larger audience.
        Our Insights Dashboards provide customizability designed for cross cultural usage
        with an adjustable focus that can highlight local issues in a rural community
        just as well as national trends that may shape public policy.`,
      icon: (mobile) => `/images/sdg/SDG-17${mobile}.png`,
      reverse: true,
    },
  ],
};

export default sdgSolutions;
